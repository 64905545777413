import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React from "react";

export default function AppSnackbar({open, message, severity}) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
            <Alert severity={severity} sx={{width: '100%', minWidth: '100px'}}>
                {message}
            </Alert>
        </Snackbar>
    )
}