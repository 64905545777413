import * as React from 'react';
import Divider from '@mui/material/Divider';
import {DrawerStyled} from "./styles";
import DrawerHeader from "./DrawerHeader";
import List from "./List";


export default function AppDrawer({open, handleDrawerClose}) {

  return (
      <DrawerStyled  variant="permanent" open={open}>
        <DrawerHeader handleDrawerClose={handleDrawerClose}/>
        <Divider />
        <List/>
      </DrawerStyled>
  );
}
