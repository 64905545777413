import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function StatusIcon({status}) {
    const colors = {
        active: 'lightgreen',
        inactive: '#aa3333'
    }

    return status ? <CheckCircleIcon style={{color: colors.active}}/>
        : <CancelIcon style={{color: colors.inactive}}/>
}