import * as React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material";

import './App.css';
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import Admin from "./Admin";


const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
        background: {
            default: '#f4f6f8',
            lighter: '#fff',
        },
        spacing: {
            topPadding: 3,
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme} className="App">
            <CssBaseline/>
            <BrowserRouter basename="/dashboard">
                <Routes>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/*" element={<Admin/>}/>

                    {/*<Route path="/*" element={<NotFound/>}/>*/}
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
