import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import * as React from "react";
import {DrawerHeaderStyled} from "./styles";
import {useTheme} from "@mui/material/styles";


export default function DrawerHeader({handleDrawerClose}) {
    const theme = useTheme();

    return (<DrawerHeaderStyled>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
            </IconButton>
        </DrawerHeaderStyled>
    );
}