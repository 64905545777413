import * as React from "react";
import LinkListItem from "./ListItem";
import MuiList from '@mui/material/List';

import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import PersonIcon from '@mui/icons-material/Person';

export default function List(open) {
    return (
        <MuiList>
            <LinkListItem open={open} text={"Services"} icon={<PhotoFilterIcon/>} to={"/services"} />
            <LinkListItem open={open} text={"Users"} icon={<PersonIcon/>} to="/users"/>
        </MuiList>
    );
}
