import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    });
api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        window.location = '/dashboard/login';
    }
    return Promise.reject(error);
});

const servicesApi = axios.create({
    baseURL: process.env.REACT_APP_SERVICES_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

servicesApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    });

servicesApi.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        window.location = '/dashboard/login';
    }
    return Promise.reject(error);
});

export {api, servicesApi};