import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import {styled} from "@mui/system";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import {servicesApi} from "../../../api";


export default function System({handlerSnackBar}) {
    const theme = useTheme();

    const StyledBox = styled(Box)(({theme}) => ({
        width: '100%',
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: 12,
        padding: '20px',
        backgroundColor: theme.palette.background.lighter,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }));

    const handleReconfigure = () => {
        servicesApi.post('/system/reconfigure').then(response => {
            setTimeout(() => handlerSnackBar(response.data.message, 'success'), 2000);
        });
    }


    return (
        <>
            <Typography variant="h4" sx={{paddingTop: theme.palette.spacing.topPadding}}>System</Typography>
            <StyledBox>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <InfoIcon sx={{marginRight: 1}}/>
                    <Box>Apply system configurations</Box>
                </Box>
                <Button variant="contained" color="primary" sx={{minWidth: '150px'}} onClick={handleReconfigure}>Apply</Button>
            </StyledBox>
        </>
    )
}
