import * as React from "react";
import {useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";

import AppLayout from "../AppLayout/AppLayout";
import {AppBox} from "./Pages/styles";
import Users from "./Pages/Users";
import {api} from "../api";
import Services from "./Pages/Services";


export default function Admin() {
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawer = (state) => {
        setOpenDrawer(state)
    }

    useEffect(() => {
        api.get('/auth/me')
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data));
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <>
            <AppLayout open={openDrawer} handleDrawer={handleDrawer}/>
            <AppBox open={openDrawer}>
                <Routes>
                    <Route path="users" element={<Users/>}/>
                    <Route path="services" element={<Services/>}/>


                    <Route path="*" element={<Navigate replace to="/services"/>}/>
                </Routes>
            </AppBox>
        </>
    );
}