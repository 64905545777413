export default class CurrentUser {
    constructor() {

        this.id = '';
        this.username = '';
        this.email = '';
        this.first_name = '';
        this.last_name = '';
        this.created_at = '';
    }

    getFullName() {
        return this.first_name + ' ' + this.last_name;
    }

    #load() {
        const userItem = localStorage.getItem('user');
        if (userItem) {
            const user = JSON.parse(userItem);
            this.id = user.id;
            this.username = user.username;
            this.email = user.email;
            this.first_name = user.first_name;
            this.last_name = user.last_name;
            this.created_at = user.created_at;
        }
    }

    getMe() {
        this.#load();
        return {
            id: this.id,
            username: this.username,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            created_at: this.created_at
        };
    }
}