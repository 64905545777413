import {Grid2, Modal} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import {servicesApi} from "../../../api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

export default function CreateService({handlerSnackBar, handleRefresh, startPolling}) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [apiEndpoint, setApiEndpoint] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleNameChange = (event) => setName(event.target.value);
    const handleDescriptionChange = (event) => setDescription(event.target.value);
    const handleApiEndpointChange = (event) => setApiEndpoint(event.target.value);
    const handleFileChange = (event) => setFile(event.target.files[0]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCreateService = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('api_endpoint', apiEndpoint);
        formData.append('file', file);

        servicesApi.post('/services/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(response => {
            setLoading(false);
            handlerSnackBar(true, "Service created successfully", "success");
            handleClose();
            resetFields();
            startPolling(response.data.id, response.data.service_id);
            handleRefresh(true);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            handlerSnackBar(true, "Service creation failed", "error");
        });
    };

    const resetFields = () => {
        setName('');
        setDescription('');
        setApiEndpoint('');
        setFile(null);
    }
    const handleCancel = () => {
        resetFields();
        handleClose();
    }

    return (
        <>
            <Button variant="contained" color="primary" sx={{marginTop: 2}} onClick={handleOpen}>Create</Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Avatar sx={{bgcolor: 'primary.main'}}>
                        <PhotoFilterIcon/>
                    </Avatar>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create Service
                    </Typography>
                    <Box component="form" onSubmit={handleCreateService} noValidate
                         sx={{width: '50%', alignItems: 'center'}}>
                        <TextField
                            margin="normal"
                            required
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                            label="Name"
                            variant="standard"
                            name="name"
                            autoComplete="name"
                        />
                        <TextField
                            margin="normal"
                            required
                            value={description}
                            onChange={handleDescriptionChange}
                            fullWidth
                            label="Description"
                            variant="standard"
                            name="description"
                            autoComplete="description"
                        />
                        <TextField
                            margin="normal"
                            required
                            value={apiEndpoint}
                            onChange={handleApiEndpointChange}
                            fullWidth
                            label="API Endpoint"
                            variant="standard"
                            name="api_endpoint"
                            autoComplete="api_endpoint"
                        />

                        <label htmlFor="file-upload" style={{marginTop: '16px'}}>Zip file with the model:</label>
                        <input
                            type="file"
                            accept=".zip"
                            onChange={handleFileChange}
                            style={{marginTop: '16px', marginRight: '10px'}}
                        />
                        <Box sx={{display: 'flex', justifyContent: 'right', width: '100%', mt: 2}}>
                            {!loading ? <Grid2 container spacing={1}>
                                <Button color="primary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleCreateService}>
                                    Create
                                </Button>
                            </Grid2> : <Typography variant="body1" sx={{color: 'primary.main', mt: 2}}>Creating
                                service...</Typography>}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}