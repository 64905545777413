import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AccountCircle from "@mui/icons-material/AccountCircle";
import React, {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {useNavigate} from "react-router-dom";
import {api} from "../api";

export default function RegisterPage() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbar, setSnackbar] = React.useState({open: false, message: '', severity: 'success'});
    const [showPassword, setShowPassword] = useState(false)

    // Handle input changes
    const handleUsernameChange = (e) => setUsername(e.target.value);
    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'error'});
    };

    const validation = () => {
        if (password !== confirmPassword) {
            setSnackbar({open: true, message: 'Passwords do not match', severity: 'error'});
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validation()) {
            return;
        }

        try {
            await api.post('/auth/register', {
                username,
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                confirm_password: confirmPassword
            });
            setSnackbar({
                open: true,
                message: `You have successfully registered, you will be redirected to Login`,
                severity: 'success'
            });
            setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
            let fullError = '';
            error.response.data.detail.forEach((error) => {
                fullError += `${error.type}: ${error.msg}\n`;
            });
            setSnackbar({open: true, message: `Login failed: \n ${fullError}`, severity: 'error'});
        }
    };
    return (
        <Container>
            <Box sx={{
                marginTop: '10%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{bgcolor: 'primary.main'}}>
                    <AccountCircle/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Welcome to Spades
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{width: '50%'}}>
                    <TextField
                        margin="normal"
                        required
                        value={username}
                        onChange={handleUsernameChange}
                        fullWidth
                        label="username"
                        variant="standard"
                        name="username"
                        autoComplete="username"
                    />
                    <TextField
                        margin="normal"
                        required
                        value={firstName}
                        onChange={handleFirstNameChange}
                        fullWidth
                        label="First Name"
                        variant="standard"
                        name="firstName"
                        autoComplete="firstName"
                    />
                    <TextField
                        margin="normal"
                        required
                        value={lastName}
                        onChange={handleLastNameChange}
                        fullWidth
                        label="Last Name"
                        variant="standard"
                        name="lastName"
                        autoComplete="lastName"
                    />
                    <TextField
                        margin="normal"
                        required
                        value={email}
                        onChange={handleEmailChange}
                        fullWidth
                        label="Email"
                        variant="standard"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        margin="normal"
                        required
                        value={password}
                        onChange={handlePasswordChange}
                        fullWidth
                        name="password"
                        label="Password"
                        variant="standard"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        variant="standard"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Register
                    </Button>
                </Box>
                {snackbar.open
                    ?
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    : null
                }
            </Box>
        </Container>
    );
}