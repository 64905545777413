import {useEffect, useState} from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import StopIcon from "@mui/icons-material/Stop";
import StatusIcon from "../../../components/StatusIcon";
import Button from "@mui/material/Button";

import {servicesApi} from "../../../api";
import TaskStatusIcon from "../../../components/TaskStatusIcon";


export default function ServiceTable({handlerSnackBar, refresh, handleRefresh, activeTasks}) {
    const [services, setServices] = useState([]);

    const fetchServices = () => {
        servicesApi.get('/services/')
            .then(response => {
                console.log(response.data);
                setServices(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        fetchServices();
    }, []);
    useEffect(() => {
        if (refresh) {
            fetchServices();
            handleRefresh(false);
        }
    }, [refresh, handleRefresh]);

    const handleActionClick = (service) => {
        const actionName = service.status ? 'disable' : 'enable';
        servicesApi.patch(`/services/${service.id}/${actionName}`).then(response => {
            console.log(response.data);
            const updatedService = service;
            updatedService.status = !service.status;
            setServices(services.map(s => s.id === service.id ? updatedService : s));
            handlerSnackBar(true, `Service ${actionName}d successfully`, 'success');
        });
    };
    const handleDeleteClick = (service) => {
        servicesApi.delete(`/services/${service.id}`).then(response => {
            console.log(response.data);
            setServices(services.filter(s => s.id !== service.id));
            handlerSnackBar(true, `Service deleted successfully`, 'success');
        }).catch(error => {
            console.error(error);
            handlerSnackBar(true, `Error deleting service`, 'error');
        });
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" sx={{textAlign: 'center'}}>
                <TableHead>
                    <TableRow>
                        <TableCell> ID </TableCell>
                        <TableCell> Name </TableCell>
                        <TableCell> Description </TableCell>
                        <TableCell> Endpoint </TableCell>
                        <TableCell> Image Name </TableCell>
                        <TableCell> Status </TableCell>
                        <TableCell> Actions </TableCell>
                        <TableCell> Task Status </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {services.map((service) => {
                            const statusAction = !service.status ? <PlayArrowIcon/> : <StopIcon/>;
                            return (
                                <TableRow
                                    key={services.id}
                                >
                                    <TableCell>{service.id}</TableCell>
                                    <TableCell>{service.name}</TableCell>
                                    <TableCell>{service.description}</TableCell>
                                    <TableCell>{service.api_endpoint}</TableCell>
                                    <TableCell>{service.image_name}</TableCell>
                                    <TableCell>
                                        <StatusIcon status={service.status}/>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleActionClick(service)}>
                                            {statusAction}
                                        </Button>
                                        <Button onClick={() => handleDeleteClick(service)} sx={{color: '#aa3333'}}>
                                            <DeleteOutlineIcon/>
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        {activeTasks.map(task => {
                                            if (task.service_id === service.id) {
                                                return (
                                                    <TaskStatusIcon status={task.status}/>
                                                );
                                            }
                                            return null;
                                        })}

                                    </TableCell>
                                </TableRow>
                            )
                        }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}