import React, {useState} from "react";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";


import System from "./System";
import ServicesTable from "./ServicesTable";
import AppSnackbar from "../../../components/AppSnackbar";
import CreateService from "./CreateService";
import {servicesApi} from "../../../api";


export default function Services() {
    const theme = useTheme();
    const [refresh, setRefresh] = useState(false);
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});

    const [activeTasks, setActiveTasks] = useState([]);

    const startPolling = (task_id, service_id) => {
        const intervalId = setInterval(() => {
            servicesApi.get(`/tasks/${task_id}`)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'completed' || response.data.status === 'failed') {
                        clearInterval(intervalId);
                        setSnackBar({open: true, message: `Task ${response.data.status}`, severity: 'success'});
                        setActiveTasks(prevTasks => prevTasks.map(task =>
                            task.task_id === task_id ? {...task, status: response.data.status} : task
                        ));
                    }
                })
                .catch(error => {
                    console.error(error);
                    clearInterval(intervalId);
                    setActiveTasks(prevTasks => prevTasks.map(task =>
                        task.task_id === task_id ? {...task, status: 'failed'} : task
                    ));
                });
        }, 5000);

        setActiveTasks(prevTasks => [...prevTasks, {task_id, intervalId, status: 'pending', service_id: service_id}]);
    };


    const handlerSnackBar = (open, message, severity) => {
        setSnackBar({open: open, message: message, severity: severity});
        setTimeout(() => setSnackBar({open: false, message: '', severity: 'success'}), 5000);
    };
    const handleRefresh = (state) => {
        setRefresh(state);
    }

    return (
        <>
            <Typography variant="h4" sx={{paddingTop: theme.palette.spacing.topPadding}}>Services</Typography>

            <ServicesTable handlerSnackBar={handlerSnackBar} refresh={refresh} handleRefresh={handleRefresh} activeTasks={activeTasks}/>
            <CreateService handlerSnackBar={handlerSnackBar} handleRefresh={handleRefresh} startPolling={startPolling}/>
            <System handlerSnackBar={handlerSnackBar}/>

            <AppSnackbar open={snackBar.open} severity={snackBar.severity} message={snackBar.message}/>
        </>
    );
}
