import {CircularProgress} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from '@mui/icons-material/Error';

export default function TaskStatusIcon({status}) {
    const colors = {
        active: 'lightgreen',
        inactive: '#aa3333'
    };

    switch (status) {
        case 'pending':
            return <CircularProgress size={20} color="secondary"/>
        case 'completed':
            return <CheckCircleIcon style={{color: colors.active}}/>
        case 'failed':
            return <ErrorIcon style={{color: colors.inactive}}/>
        default:
            return <CircularProgress size={20} color="secondary"/>
    }
}