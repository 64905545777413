import * as React from "react";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiListItem from '@mui/material/ListItem';
import {Link} from "react-router-dom";


export default function LinkListItem({open, text, icon, to}) {
    return (
        <MuiListItem key={text} disablePadding sx={{display: 'block'}} button component={Link} to={to}>
            <ListItemButton
                sx={[
                    {
                        minHeight: 48,
                        px: 2.5,
                    },
                    open
                        ? {
                            justifyContent: 'initial',
                        }
                        : {
                            justifyContent: 'center',
                        },
                ]}
            >
                <ListItemIcon
                    sx={[
                        {
                            minWidth: 0,
                            justifyContent: 'center',
                        },
                        open
                            ? {
                                mr: 3,
                            }
                            : {
                                mr: 'auto',
                            },
                    ]}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    sx={[
                        open
                            ? {
                                opacity: 1,
                            }
                            : {
                                opacity: 0,
                            },
                    ]}
                />
            </ListItemButton>
        </MuiListItem>
    )
}
