import React, {useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {api} from "../api";

const LoginPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [snackbar, setSnackbar] = React.useState({open: false, message: '', severity: 'success'});
    const [showPassword, setShowPassword] = useState(false);

    // Handle input changes
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleCloseSnackbar = () => {
        setSnackbar({open: false, message: '', severity: 'error'});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/auth/login', {email, password});
            localStorage.setItem('token', response.data.access_token);
            navigate('/');
        } catch (error) {
            let fullError = '';
            error.response.data.detail.forEach((error) => {
                fullError += `${error.type}: ${error.msg}\n`;
            });
            setSnackbar({open: true, message: `Login failed: \n ${fullError}`, severity: 'error'});
        }
    };

    return (
        <Container component="main">
            <Box
                sx={{
                    marginTop: '10%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{bgcolor: 'primary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        value={email}
                        onChange={handleEmailChange}
                        fullWidth
                        label="Email"
                        variant="standard"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        margin="normal"
                        required
                        value={password}
                        onChange={handlePasswordChange}
                        fullWidth
                        name="password"
                        label="Password"
                        variant="standard"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                </Box>
                {snackbar.open
                    ?
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    : null
                }
                Don't have an account? <Link to="/register">Press here to register</Link>
            </Box>
        </Container>
    );
};

export default LoginPage;
