import {styled} from '@mui/system';
import {Box} from '@mui/material';

export const AppBox = styled(Box)(({theme, open}) => ({
    // border: '1px solid red',
    marginTop: '65px',
    marginLeft: open ? '240px' : '65px',
    height: '100vh',
    padding: '160px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.up('md')]: {
        // width: '80%',
        padding: '24px',
    },
}));