import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";


import {api} from "../../../api";
import CurrentUser from "../../../me";
import Typography from "@mui/material/Typography";

export default function Users() {
    const theme = useTheme();

    const [users, setUsers] = useState([]);
    const currentUser = (new CurrentUser()).getMe();

    useEffect(() => {
        api.get('/users')
            .then(response => {
                console.log(response.data);
                setUsers(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <>
            <Typography variant="h4" sx={{paddingTop: theme.palette.spacing.topPadding}}>Users</Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell> Username </TableCell>
                            <TableCell> Email </TableCell>
                            <TableCell> Full Name </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => {
                                const styleSx = user.id === currentUser.id ? {backgroundColor: 'lightgrey'} : {};
                                return (
                                    <TableRow
                                        key={user.id}
                                        sx={styleSx}
                                    >
                                        <TableCell>{user.username}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.first_name + " " + user.last_name}</TableCell>
                                    </TableRow>
                                )
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}