import * as React from "react";

import AppBar from './AppBar';
import AppDrawer from './Drawer';


export default function AppLayout({open, handleDrawer}) {


    return (
        <>
            <AppBar open={open} handleDrawerOpen={() => handleDrawer(true)}/>
            <AppDrawer open={open} handleDrawerClose={() => handleDrawer(false)}/>
        </>
    );
}

